import { HeadingTag, Link, ModuleBase } from '@/components';
import { isColorDark } from '@/utils';
import ArrowRight from 'assets/arrowRight.svg';
import classNames from 'classnames';

const ContactsModule = ({ data }) => {
  const darkBg = isColorDark(data.backgroundColour);

  return (
    <ModuleBase data={data} className="font-normal">
      <div className="main-wrapper container font-montserrat">
        <div
          className={classNames(
            'text-wrapper w-full',
            (data.leftColumnContacts.length === 1 && !data.rightColumnContacts.length) ||
              (data.rightColumnContacts.length === 1 && !data.leftColumnContacts.length)
              ? 'text-center flex flex-col items-center'
              : '',
          )}
        >
          {data.headingTitle && (
            <HeadingTag
              data={data.headingTitle}
              className="max-w-[392px] color-from-bg text-base leading-5 tracking-headline-m-tablet md:text-xl md:leading-6 md:tracking-title-m uppercase font-semibold"
            />
          )}
          {data.description && (
            <div
              className="color-from-bg description text-sm leading-6 mt-4 lg:-tracking-[0.14px] max-w-[392px]"
              dangerouslySetInnerHTML={{ __html: data.description }}
            />
          )}
        </div>
        <div
          className={classNames(
            'contacts-wrapper flex flex-col',
            data.rightColumnContacts.length > 0 && data.leftColumnContacts.length <= 1 ? 'lg:flex-row lg:gap-6' : '',
          )}
        >
          {data.leftColumnContacts.length > 0 && (
            <div className="primary-contacts-row w-full flex flex-col gap-7 lg:flex-row lg:gap-6 color-from-bg">
              {data.leftColumnContacts.map((contact, index) => (
                <div
                  className={classNames(
                    'contact w-full',
                    data.leftColumnContacts.length === 1 && !data.rightColumnContacts.length
                      ? 'flex flex-col items-center'
                      : '',
                  )}
                  key={index}
                >
                  {contact.contactTitle && (
                    <p
                      className={classNames(
                        'text-sm tracking-title-s font-semibold leading-normal uppercase',
                        data.description.length > 0 ? 'my-10' : 'my-6',
                      )}
                    >
                      {contact.contactTitle}
                    </p>
                  )}
                  {contact.phoneNumber && (
                    <a
                      href={`tel:${contact.phoneNumber}`}
                      className={`contact-phone text-sm leading-6 flex items-center gap-3 before:content-phone before:h-6 underline underline-offset-[6px] mb-4 ${
                        darkBg ? 'before:invert' : ''
                      }`}
                    >
                      {contact.phoneNumber}
                    </a>
                  )}
                  {contact.emailAddress && (
                    <a
                      href={`mailto:${contact.emailAddress}`}
                      className={classNames(
                        'contact-mail text-sm leading-6 flex items-center gap-3 before:content-mail before:h-6 underline underline-offset-[6px]',
                        darkBg ? 'before:invert' : '',
                        contact.instagramHandle ? 'mb-4' : '',
                      )}
                    >
                      {contact.emailAddress}
                    </a>
                  )}
                  {contact.instagramHandle && (
                    <a
                      href={`https://www.instagram.com/${contact.instagramHandle.includes('@') ? contact.instagramHandle.split('@')[1] : contact.instagramHandle}/`}
                      target="_blank"
                      className={classNames(
                        'contact-instagram text-sm leading-6 flex items-center gap-3 before:content-instagram before:h-6 underline underline-offset-[6px]',
                        darkBg ? 'before:invert' : '',
                      )}
                    >
                      {contact.instagramHandle.includes('@') ? contact.instagramHandle : `@${contact.instagramHandle}`}
                    </a>
                  )}
                </div>
              ))}
            </div>
          )}
          {data.rightColumnContacts.length > 0 && (
            <div className="secondary-contacts-row w-full mt-[72px] flex flex-col gap-7 lg:flex-row lg:gap-6 lg:mt-0 color-from-bg">
              {data.rightColumnContacts.map((contact, index) => (
                <div
                  className={classNames(
                    'contact w-full',
                    data.rightColumnContacts.length === 1 && !data.leftColumnContacts.length
                      ? 'flex flex-col items-center'
                      : '',
                  )}
                  key={index}
                >
                  {contact.contactTitle && (
                    <p
                      className={classNames(
                        'text-sm tracking-title-s font-semibold leading-normal uppercase',
                        data.description.length > 0 ? 'my-10 first:mt-0 first:lg:mt-10' : 'my-6',
                      )}
                    >
                      {contact.contactTitle}
                    </p>
                  )}
                  {contact.phoneNumber && (
                    <a
                      href={`tel:${contact.phoneNumber}`}
                      className={classNames(
                        'contact-phone text-sm leading-6 flex items-center gap-3 before:content-phone before:h-6 underline underline-offset-[6px] mb-4',
                        darkBg ? 'before:invert' : '',
                      )}
                    >
                      {contact.phoneNumber}
                    </a>
                  )}
                  {contact.emailAddress && (
                    <a
                      href={`mailto:${contact.emailAddress}`}
                      className={classNames(
                        'contact-mail text-sm leading-6 flex items-center gap-3 before:content-mail before:h-6 underline underline-offset-[6px]',
                        darkBg ? 'before:invert' : '',
                        contact.instagramHandle ? 'mb-4' : '',
                      )}
                    >
                      {contact.emailAddress}
                    </a>
                  )}
                  {contact.instagramHandle && (
                    <a
                      href={`https://www.instagram.com/${contact.instagramHandle.includes('@') ? contact.instagramHandle.split('@')[1] : contact.instagramHandle}/`}
                      target="_blank"
                      className={`contact-instagram text-sm leading-6 flex items-center gap-3 before:content-instagram before:h-6 underline underline-offset-[6px] ${
                        darkBg ? 'before:invert' : ''
                      }`}
                    >
                      {contact.instagramHandle.includes('@') ? contact.instagramHandle : `@${contact.instagramHandle}`}
                    </a>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
        {data.primaryCTA && (
          <div className="CTA-wrapper mt-14 md:mt-16 lg:mt-20 flex">
            <Link
              className={classNames(
                'btn w-full md:w-fit',
                darkBg ? 'primary color-from-bg' : 'primary-dark',
                (data.leftColumnContacts.length === 1 && !data.rightColumnContacts.length) ||
                  (data.rightColumnContacts.length === 1 && !data.leftColumnContacts.length)
                  ? 'mx-auto'
                  : '',
              )}
              link={data.primaryCTA}
            >
              <span>{data.primaryCTA.text}</span>
              <ArrowRight role="presentation" className="!ml-6 !stroke-none !fill-current" />
            </Link>
          </div>
        )}
      </div>
    </ModuleBase>
  );
};

export default ContactsModule;
